/* eslint no-console:0 */
/* eslint no-alert:0 */
/* eslint jsx-a11y/no-noninteractive-element-interactions:0 */
import React from 'react';
import withWrapper from '../components/wrapper.js';
import { Helmet } from "react-helmet";


function FAQ_inner () {
  return (
        <div>
          <Helmet>
            <meta charSet="utf-8" />
            <title>FAQ</title>
            <link rel="canonical" href="https://www.valtrace.com/faq/" />
            <html lang="en" />
          </Helmet>
        
          <h1><span className='hsp'>FAQ</span><span className='hsp2'>Last updated: Sunday, 12 April, 2020</span></h1>
          <p>This FAQ should answer most questions, but please send us feedback if this doesn't cover your question.</p>
          
          <h3>How do you make your estimates for the next quarter?</h3>
          <p>We use a custom neural network that has been trained on public company financials.</p>
          <h3>How do you trace the value of a company back to its fundamentals?</h3>
          <p>The neural network has been trained to come up with the companies value based on it's fundamentals.  We are able to determine the change in value if one of the fundiments changes.</p>
          <h3>Where do you get your raw data from?</h3>
          <p>Our raw data comes primarily from <a href='http://simfin.com'>simfin.com</a></p>
          <h3>How accurate are your estimate?</h3>
          <p>Testing showed an expected error of around 20%. However, systemic errors caused by major events could cause this is be greater.</p>
          <h3>Why don't you have all public companies?</h3>
          <p>Simfin doesn't have complete data on all public companies, and some of its data has errors in it.  The coverage and data quality are improving, and our estimates will follow.</p>
          <h3>How can I use the estimates?</h3>
          <p>The system forcasts fundimentals based on current share prices.  You can see how a companies' value will change based on a fundimentals change.</p>
        	<h3>How do you trace a change in a fundimental to a change in value?</h3>
        	<p>This is another proprietary neural network that we use to trace a change in valuation to a change in one or more fundimentals.</p>
        </div>
  );
}

export default withWrapper(FAQ_inner);